import withAuth, { AuthProps } from "components/decorator/WithAuth"
import { getLoginPageTitle, getTrackingId } from "components/Login"
import { LoginProps, withLogin } from "components/Login/withLogin"
import { CountryCode, countryData } from "DataMapper/CountryCode"
import { NextPage } from "next"
import { useRouter } from "next/router"
import { ieltsFlowPages, navRoutes } from "page_routes"
import { useEffect, useState } from "react"
import { getObjectKeys } from "utils/ielts_utils"
import { validateAndRedirectForIeltsForm } from "components/Login/utils"
import Head from "next/head"

type Props = AuthProps & LoginProps
const getFlowType = (url: string | undefined | string[]): string => {
  let defaultFlowType = ""
  if (typeof url == "string") {
    defaultFlowType = ieltsFlowPages.some((regex) => regex.test(url))
      ? "ielts"
      : "counselling"
  } else {
    defaultFlowType = "counselling"
  }
  if (Array.isArray(url) || !url) {
    return defaultFlowType
  }
  const flowTypeFromQuery = new URL("https://test.com/" + url).searchParams.get(
    "flowType",
  )
  if (typeof flowTypeFromQuery === "string") {
    return flowTypeFromQuery as string
  }
  return defaultFlowType
}

type LoginContentFromStrapi = {
  desktopImage: {
    url: string
  }
  mobileImage: {
    url: string
  }
  title: string
  subtitle: string
  description: string
}

const LoginPage: NextPage<Props> = ({ initLogin, auth }) => {
  const router = useRouter()
  const [content, setContent] = useState<LoginContentFromStrapi | null>(null)

  useEffect(() => {
    handleLogin()
  }, [content])

  useEffect(() => {
    const fetchLoginContent = async (id: string) => {
      try {
        const res = await fetch(`${process.env.CMS_URL}/modals?type=${id}`)
        const data = await res.json()
        if (data?.length) {
          const content = data[0]?.modalContent?.[0]
          if (content) setContent(content)
        }
      } catch (error) {
        console.error(
          "Error in fetching login content content from strapi",
          error,
        )
      }
    }
    if (router.isReady) {
      const loginContentType = router.query.loginContentType
      if (loginContentType) {
        fetchLoginContent(loginContentType as string)
      }
    }
  }, [router.isReady])

  const handleLogin = () => {
    let callbackUrl = (router.query.callbackUrl as string) || navRoutes.PROFILE
    const trackingId = getTrackingId(callbackUrl, "tracking_id")
    const url = new URL("https://test.com/" + callbackUrl)
    const prefilledPhone = url.searchParams.get("prefilled_phone")
    const countryCodeFromParam = url.searchParams.get("countryCode")
    let country: CountryCode | undefined

    if (countryCodeFromParam) {
      country = getObjectKeys(countryData).find(
        (item) => countryData[item].code === countryCodeFromParam,
      )
    }
    if (auth?.isValid) {
      validateAndRedirectForIeltsForm(callbackUrl)
    } else {
      const title = content?.title || getLoginPageTitle(callbackUrl, trackingId)
      const imageUrl = content?.desktopImage?.url
      const flowType = getFlowType(router.query.callbackUrl)
      initLogin(
        title,
        false,
        prefilledPhone || "",
        flowType,
        (isNewUser: boolean) => {
          callbackUrl += callbackUrl.includes("?") ? "&" : "?"
          callbackUrl += `isNewUser=${isNewUser ? "1" : "0"}`
          validateAndRedirectForIeltsForm(callbackUrl)
        },
        country,
        undefined,
        imageUrl,
      )
    }
  }
  return (
    <Head>
      <meta name="robots" content="noindex, nofollow" />
    </Head>
  )
}

export default withAuth(withLogin(LoginPage), false)
